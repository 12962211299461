import React, {useEffect, useState} from 'react';
import { gsap } from "gsap";
import axios from 'axios';
import '../static/css/contactsPage.css';

import Footer from '../components/Footer';

import { ReactComponent as ArrowDown } from '../assets/arrow-down.svg';

const ContactsPage = () => {

    let [contactsMessage, setContactsMessage] = useState('');
    let [fullName, setFullName] = useState('');
    let [email, setEmail] = useState('');

    let sendContactsInformation = () => {
        axios.post('https://www.archisoul.cz/api/send-contact-info/', {
            full_name: fullName,
            email: email,
            message: contactsMessage
        });
    }

    useEffect(() => {
        /*Ripple button effect*/
        const rippleBtns = document.querySelectorAll(".ripple");
        let ripples = document.createElement("span");

        const buttonAnimationTimeline = gsap.timeline();
        buttonAnimationTimeline.to(ripples, {width: "500px", height: "500px", opacity: "1", duration: 0.3, delay: 0});
        buttonAnimationTimeline.reversed(true);

        rippleBtns.forEach((currentButton) => {
            currentButton.addEventListener("mouseover", function (e) {
                let x = e.clientX - e.target.offsetLeft;
                let y = e.clientY - e.target.offsetTop;
                ripples.style.left = x + "px";
                ripples.style.top = y + "px";
                this.appendChild(ripples);
                buttonAnimationTimeline.reversed(!buttonAnimationTimeline.reversed());
            });

            currentButton.addEventListener("mouseout", function () {
                buttonAnimationTimeline.reversed(!buttonAnimationTimeline.reversed());
            });
        });
    });

  return (
    <div className='d-block'>
        <section className='container-fluid d-flex justify-content-center overflow-hidden bg-color-light move-from-header' style={{height: "auto"}}>
            <div className='w-100 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-around align-items-center align-items-lg-start'>
                <div className='col-11 col-md-10 col-lg-4 mt-5'>
                    <h1 className='text-section text-color-dark font-anton' style={{textTransform: "uppercase"}}>POJĎME NA TO, ZAČÍNÁME</h1>

                    <div className='mt-4'>
                        <div className='mt-4'>
                            <h5 className='font-antonio m-0'>Sídlo</h5>
                            <h2 className='font-antonio m-0'>Wuchterlova 584/16, 16000 Praha 6</h2>
                        </div>

                        <div className='mt-4'>
                            <h5 className='font-antonio m-0'>EMAIL</h5>
                            <h2 className='font-antonio m-0'>info@archisoul.cz</h2>
                        </div>

                        <div className='mt-4'>
                            <h5 className='font-antonio m-0'>Mobil</h5>
                            <h2 className='font-antonio m-0'>+420 775 037 148</h2>
                        </div>
                    </div>
                </div>

                <div className='col-11 col-md-10 col-lg-5 my-5'>
                    <form className='d-flex flex-column justify-content-center align-items-center' onSubmit={sendContactsInformation}>
                        <h1 className='font-anton text-section'>Poptejte nás</h1>

                        <div className='w-100 my-3 d-flex flex-column flex-md-row justify-content-between'>
                            <div className='col-12 col-md-5'>
                                <label className='fs-5 fw-bold font-antonio' htmlFor='full-name'>Jméno a příjmění</label>
                                <input type='text' className='w-100 fs-4 font-antonio border-0 border-bottom border-dark border-2 my-2 bg-color-light' id='full-name' placeholder='Jméno a příjmění' onChange={(e) => setFullName(e.target.value)} />
                            </div>

                            <div className='col-12 col-md-5'>
                                <label className='fs-5 fw-bold font-antonio' htmlFor='email'>Email</label>
                                <input type='email' className='w-100 fs-4 font-antonio border-0 border-bottom border-dark border-2 my-2 bg-color-light' placeholder='Email' id='email' onChange={(e) => setEmail(e.target.value)} />
                            </div>
                        </div>

                        <div className='w-100 my-3'>
                        <label className='fs-5 fw-bold font-antonio' htmlFor='message'>Vaše zpráva</label>
                            <textarea className='w-100 fs-4 form-control font-antonio border-0 border-bottom border-dark border-2 my-2 bg-color-light' placeholder='S čím Vám můžeme pomoci...' id='message' style={{height: '25vh'}} onChange={(e) => setContactsMessage(e.target.value)}></textarea>
                        </div>

                        <div className='w-100 d-flex flex-column flex-xl-row-reverse align-items-center'>
                            <div className='col-12 col-xl-7 mx-5 my-3 d-flex flex-row align-items-start'>
                                <input type='checkbox' className='mt-2 mx-2' style={{width: '6%'}} required/>
                                <p className='fs-5 font-inter m-0'>Odesláním souhlasíte se <a href='/privacy-and-policy/' target="_blank">zpracováním osobních údajů</a></p>
                            </div>
                            <button className='w-auto py-2 px-4 bg-color-skin text-color-dark font-anton fs-4 border-0 rounded-5'>Odeslat <ArrowDown className="ms-2" /></button>
                        </div>
                    </form>
                </div>
            </div>
        </section>

        <Footer />
    </div>
  )
}

export default ContactsPage